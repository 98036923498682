<template>
	<div class="cs-jobs">
		<div class="header-content">
			<h2 class="cs-title" v-if="data.title">
				<ColorSplit :data="data.title" />
			</h2>
			<h3 v-if="data.subTitle" class="cs-sub-title">{{ data.subTitle }}</h3>
			<span v-if="data.text" class="cs-text" v-html="data.text"></span>
		</div>
		<div class="row select-wrapper">
			<div :class="columnClasses">
				<DropdownFilter
				@filter-updated="filterUpdated"
				:data="{
					filters: departments,
					label: data.departmentLabel,
					key: 'colDepartment',
				}"
				/>
			</div>
			<div :class="columnClasses">
				<DropdownFilter
				@filter-updated="filterUpdated"
				:data="{
					filters: languages,
					label: data.languageLabel,
					key: 'colLanguage',
				}"
				/>
			</div>
			<div :class="columnClasses">
				<DropdownFilter
				@filter-updated="filterUpdated"
				:data="{
					filters: locations,
					label: data.locationLabel,
					key: 'colLocation',
				}"
				/>
			</div>
			<div :class="columnClasses">
				<DropdownFilter
				@filter-updated="filterUpdated"
				:data="{ filters: roles, label: data.roleLabel, key: 'colRole' }"
				/>
			</div>
		</div>
		<div class="row card-container">
			<div
				class="col-sm-6 col-md-4 col-lg-3"
				v-for="(card, key) in cardsToShow"
				:key="key"
				>
				<JobCard
				:data="{
					job: card,
					languageLabel: data.languageLabel,
					detailsLabel: data.detailsLabel,
					departmentLabel: data.departmentLabel,
					locationLabel: data.locationLabel,
					roleLabel: data.roleLabel,
					fn:jobOpenModal
				}"
				/>
			</div>
    	</div>
		<!-- The Modal -->
		<div @click="jobCloseModal($event)" class="cs-modal" :class="jobOpenedModal">
			<div class="cs-modal-content">
				<span class="close">&times;</span>
				<div class="row">
					<div class="col-lg-6" >
						<h3 v-if="job_modal.title">
							<ColorSplit :data="job_modal.title" />
						</h3>
						<span class="cs-text" v-html="job_modal.text"></span>
					</div>
					<div class="col-lg-6" >
						<template v-if="job_modal.form">
							<CSForm :id="job_modal.form" ></CSForm>
						</template>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
	import { mapActions } from "vuex"

	export default {
		components: {
			DropdownFilter: () => import("../components/CSUtilsDropdownFilter.vue"),
			JobCard: () => import("../components/CSUtilsJobCard.vue"),
			ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
			CSForm: () => import("../components/CSForm.vue") 
		},
		name: "CSJobs",
		data() {
			return {
				cardsToShow: [],
				cardsToSave: [],
				colLocation: "",
				colDepartment: "",
				colLanguage: "",
				colRole: "",
				departments: [],
				languages: [],
				locations: [],
				roles: [],
				page: 0,
				limit: 8,
				job_modal:{},
				rand:0,
				jobOpenedModal:'close'
			}
		},

		props: {
			data: {
				type: Object,
				default: () => { return {} },
			},
		},
		computed: {
			randId(){
				return `job_popup_${this.rand}`
			},
			columnClasses() {
				return "col-sm-6 col-md-3"
			},
		},
		methods: {
			...mapActions("jobs", ["getJobs"]),
			filterUpdated(filter) {
				this[filter.key] = filter.value
			},
			async filterPosts() {
				this.page = 0

				await this.loadCards(this.page, this.limit)
				try {
					this.cardsToShow = this.cardsToSave.filter(e => {
						return (
							(this.colRole === "" || e.role === this.colRole) &&
							(this.colDepartment === "" ||
								e.department === this.colDepartment) &&
							(this.colLanguage === "" || e.language === this.colLanguage) &&
							(this.colLocation === "" || e.location === this.colLocation)
							)
					})
				} catch (error) {
					console.error("Error filtering posts:", error)
				}
			},
			checkObj(obj) {
				if (this.cardsToShow.some(obj1 => obj1._id === obj._id)) return false
					return (
						(this.colRole === "" || obj.role === this.colRole) &&
						(this.colDepartment === "" || obj.department === this.colDepartment) &&
						(this.colLanguage === "" || obj.language === this.colLanguage) &&
						(this.colLocation === "" || obj.location === this.colLocation)
						)
			},
			jobOpenModal(a){
				this.jobOpenedModal = 'open';
				this.job_modal = a.job
			},
			jobCloseModal(el){
				if(['modal open','close'].includes(el.target.className)){
					this.jobOpenedModal = 'close';
				}
			},
			submitForm(req) {
				console.log(req)
			},
			loadCards(page, limit) {
				this.getJobs({ page: page, tags:this.data.tags })
				.then(res => {
					if(res.jobs && res.jobs.data){
						if (!res.jobs.pages.hasNext) {
							document.removeEventListener("scroll", this.checkPosition)
						}
						res.jobs.data.forEach(card => {
							if (this.checkObj(card)) {
								this.cardsToShow.push(card)
								this.cardsToSave = this.cardsToShow
							}
						})
						this.page++
					}
				})
				.catch(er => {
					console.log(er)
				})
			},
			checkPosition() {
				let cards = document.querySelectorAll(".job-card")

				if (cards && cards.length) {
					if (
						document.documentElement.clientHeight -
						cards[cards.length - 1].getBoundingClientRect().top >=
						300
						) {
						this.loadCards(this.page, this.limit)
				}
			}
		},
	},
	mounted() {
		this.rand = parseInt(Math.random()*99999999999999);

		this.getJobs({ page: 0, tags:this.data.tags })
		.then(res => {

			if(res && res.jobs && res.jobs.filters){
				this.departments = res.jobs.filters.departments
				this.languages = res.jobs.filters.languages
				this.locations = res.jobs.filters.locations
				this.roles = res.jobs.filters.roles

				if(res.jobs.data) this.cardsToShow = this.cardsToSave = Object.values(res.jobs.data)
			}
	})
		.catch(er => console.log(er))

		this.page++
		document.addEventListener("scroll", this.checkPosition)
	},
	watch: {
		colDepartment: "filterPosts",
		colLanguage: "filterPosts",
		colLocation: "filterPosts",
		colRole: "filterPosts",
	},
}
</script>

<style lang="scss" scoped>
	.cs-jobs ::v-deep .dropdown-filter .dropdown-wrapper div svg {
	    z-index: 1 !important;
	}
	.header-content{
		text-align:center;
		margin-bottom:30px;	
	}

	.cs-modal span.cs-text * {
		width: 100%;
	}

	.cs-modal span.cs-text {
		max-height: 600px;
		overflow: auto;
		display: block;
	}

	.cs-modal {
		position: fixed; 
		z-index: 1; 
		left: 0;
		top: 0;
		width: 100%; 
		height: 100%; 
		overflow: hidden;
		&.open{
			display: flex !important;
		    align-items: center;
		    justify-content: center;
		}
		&-content {
		    margin: 0 !important; 
			width: 80%; 
        	max-height: 80%;
        	overflow-y: auto;



			::v-deep div#CS-form {
			    box-shadow: none;
			}

			.cs-text {
				* {
					max-width: 100%;
					object-fit: contain;
					object-position: center;
				}

				@media (max-width: 1199.98px) {
					max-height: unset !important;
				}
			}
		}
	}
</style>
